let resumeData = {
    "name": "Karthik",
    "role": "Mobile Applications and Backend Developer",
    "roleDescription": "Experienced Professional more than 6 years of total IT industry experience in developing mobile application for iOS, Android, Backend, Enterprise application, MVC, MVVM architecture using Swift, Objective-C, Java, Golang, ReactJs, Kotlin, C/C++, PHP, HTML, CSS and etc ...",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/karthik-v-87485656/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/vrkkarthi",
          "className":"fa fa-github"
        }
      ]
  }
  export default resumeData